var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "transitPage" }, [
    _c("div", { staticClass: "content" }, [
      _vm._m(0),
      _c("div", { staticClass: "continue" }, [
        _vm._v("您访问的链接即将离开本公众号，是否继续？"),
      ]),
      _vm._m(1),
      _c("div", { staticClass: "btn" }, [
        _c("button", { on: { click: _vm.confirm } }, [_vm._v("确定")]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "cont-img" }, [
      _c("img", {
        attrs: { src: require("@/assets/haiyuansu/logo.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "confirm" }, [
      _c("span", { staticClass: "tip" }, [_vm._v("温馨提示：")]),
      _c("br"),
      _c("span", { staticClass: "tip-cont" }, [
        _vm._v(
          "本次活动礼品均由供应商提供，如有礼品相关问题可直接联系：0898-32191718,点击确定按钮跳转外链，点击左上角“X”返回当前页。"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }