<template>
  <div class="transitPage">
    <div class="content">
      <div class="cont-img">
        <img src="@/assets/haiyuansu/logo.png" alt="">
      </div>
      <div class="continue">您访问的链接即将离开本公众号，是否继续？</div>
      <p class="confirm">
        <span class="tip">温馨提示：</span><br>
        <span class="tip-cont">本次活动礼品均由供应商提供，如有礼品相关问题可直接联系：0898-32191718,点击确定按钮跳转外链，点击左上角“X”返回当前页。</span>
      </p>
      <div class="btn">
        <button @click="confirm">确定</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'transitPage',
  data() {
    return {}
  },
  methods: {
    confirm() {
      this.$router.replace('/home')
      localStorage.removeItem('businessInfo')
    }
  },
  mounted() {
    document.title = this.$route.meta.title;
  }
}
</script>

<style lang="less" scoped>
@import "../../common/less/variable.less";
@import "../../common/less/mixin.less";

  .transitPage {
    .wh(100vw, 100vh);
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    .sc(0.28rem, #666);
    letter-spacing: 0.02rem;

    .content {
      margin: 0 0.7rem;

      .cont-img {
        text-align: center;
        
        img {
          width: 2rem;
        }
      }

      .continue {
        padding: 0.9rem 0 0.6rem 0;
      }

      .confirm {

        .tip {
          color: var(--main-color);
        }

        .tip-cont {
          line-height: 0.5rem;
        }
      }

      .btn {
        text-align: center;

         button {
          background: var(--main-color);
          color: #fff;
          margin-top: 0.2rem;
          margin-bottom: 0.2rem;
          width: 2rem;
          height: 0.68rem;
          border-radius: 0.68rem;
          text-align: center;
          line-height: 0.68rem;
          font-size: 0.28rem;
          border: none;
        }
      }
    }
  }
</style>